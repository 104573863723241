import {Item} from "semantic-ui-react";

export default function LinksOtherWeb() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h1'>Links to Other Websites</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          Our Service may contain links to other websites that are not operated by Us. If You click on a third party
          link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of
          every site You visit.<br/><br/>

          We have no control over and assume no responsibility for the content, privacy policies or practices of any
          third party sites or services.
        </Item.Description>
      </Item>
    </>
  )
}