import React from "react";
import {useNavigate} from "react-router-dom";
import {
  Button,
  Container, Grid,
  Header,
  Icon,
  Image,
  Segment,
} from "semantic-ui-react";
import {pricingData} from "./pricing/pricing";
import PricingComponent from "./pricing/PricingComponent";
import GroupsComponent from "./pricing/GroupsComponent";
import FooterComponent from "../footer/FooterComponent";
import {useTranslation} from "react-i18next";

export default function HomePage() {

  const navigate = useNavigate();

  const {t} = useTranslation();


  return (
    <>
      <Segment inverted textAlign='center' vertical className='masthead'>
        <Container>
          <Header as='h1' inverted>
            <Image
              size='massive'
              src='/assets/logo.png'
              style={{marginBottom: 12}}
            />
            {t('welcome')}
          </Header>
          <Button size='huge' inverted onClick={() => navigate('/events')}>
            {t('getStarted')}
            <Icon name='right arrow' inverted/>
          </Button>
          <p style={{marginTop: 20, fontSize: '1.5em', textAlign: 'center'}}>{t('learnMore')}</p>
        </Container>
      </Segment>
      <Segment style={{padding: '8em 0em'}} vertical>
        <Grid container stackable verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as='h3' style={{fontSize: '2em'}}>
                {t('part2-1')}
              </Header>
              <p style={{fontSize: '1.33em'}}>{t('part2-1Text')}</p>
              <Header as='h3' style={{fontSize: '2em'}}>
                {t('part2-2')}
              </Header>
              <p style={{fontSize: '1.33em'}}>{t('part2-2Text')}</p>
              <Header as='h3' style={{fontSize: '2em'}}>
                {t('part2-3')}
              </Header>
              <p style={{fontSize: '1.33em'}}>{t('part2-3Text')}</p>
            </Grid.Column>
            <Grid.Column width={6}>
              <Image bordered size='large' src='/assets/categoryImages/culture.jpg'/>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment style={{padding: '0em'}} vertical>
        <Header as='h3' style={{fontSize: '4em'}} content={t('pricing')} textAlign='center'/>
        <Grid columns='equal' stackable>
          <Grid.Row centered>
            {pricingData.map((pricing) => (
              <Grid.Column style={{paddingBottom: '5em', paddingTop: '5em'}} width={5} key={pricing.id}>
                <Header as='h2' textAlign='center'>{pricing.tier}</Header>
                <PricingComponent price={pricing}/>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment style={{padding: '0em'}} vertical>
        <Header as='h3' style={{fontSize: '4em'}} content={t('groups')} textAlign='center'/>
        <GroupsComponent/>
      </Segment>
      <FooterComponent />
    </>
  );
}
