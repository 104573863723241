import {Container, Grid, Header, List, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import { useTranslation} from "react-i18next";

export default function FooterComponent() {

	const {t} = useTranslation();


	return (
		<Segment inverted vertical style={{padding: '5em 0em'}}>
			<Container>
				<Grid divided inverted stackable>
					<Grid.Row>
						<Grid.Column width={3}>
							<Header inverted as='h4' content={t('aboutUs')} />
							<List link inverted>
								<List.Item as={Link} to='/feedback' content={t('feedback')}/>
								<List.Item as={Link} to={'/privacy'} content={t('feedback')} />
								<List.Item as={Link} to={'/terms'} content={t('terms')} />
							</List>
						</Grid.Column>
						<Grid.Column width={3}>
							<Header inverted as='h4' content={t('services')} />
							<List link inverted>
								<List.Item as={Link} to='/events' content={t('events')}/>
							</List>
						</Grid.Column>
						<Grid.Column width={7}>
							<Header as='h4' inverted>
								{t('footMain')}
							</Header>
							<p>
								{t('footTag')}
							</p>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		</Segment>
	)
}