import {Item} from "semantic-ui-react";

export default function TermsTermination() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h1'>Termination</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          We may terminate or suspend Your access immediately, without prior notice or liability, for any reason
          whatsoever, including without limitation if You breach these Terms and Conditions.<br/><br/>

          Upon termination, Your right to use the Service will cease immediately.<br/><br/>
        </Item.Description>
      </Item>
    </>
  )
}