import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Header, Image, Item, Segment} from 'semantic-ui-react';
import {format} from 'date-fns';
import {toast} from 'react-toastify';
import {
  addUserAttendance,
  cancelUserAttendance,
} from '../../../app/firestore/firestoreService';
import {useSelector} from "react-redux";
import UnauthModal from "../../auth/UnauthModal";
import {useTranslation} from "react-i18next";

const eventImageStyle = {
  filter: 'brightness(30%)',
};

const eventImageTextStyle = {
  position: 'absolute',
  bottom: '5%',
  left: '5%',
  width: '100%',
  height: 'auto',
  color: 'white',
};

export default function EventDetailedHeader({event, isGoing, isHost}) {
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const {authenticated} = useSelector((state) => state.auth);

  const {t} = useTranslation();


  async function handleJoinEvent() {
    setLoading(true);
    try {
      await addUserAttendance(event);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleLeaveEvent() {
    setLoading(true);
    try {
      await cancelUserAttendance(event);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {modalOpen &&
        <UnauthModal setModalOpen={setModalOpen}  clicked={true}/>
      }

      <Segment.Group>
        <Segment basic attached='top' style={{padding: '0'}}>
          <Image
            src={`/assets/categoryImages/${event.category}.jpg`}
            fluid
            style={eventImageStyle}
          />

          <Segment basic style={eventImageTextStyle}>
            <Item.Group>
              <Item>
                <Item.Content>
                  <Header
                    size='huge'
                    content={event.title}
                    style={{color: 'white'}}
                  />
                  <p>{format(event.date, 'MMMM d, yyyy h:mm a')}</p>
                  <p>
                    {t('hostedBy')}{' '}
                    <strong>
                      <Link to={`/profile/${event.hostUid}`}>
                        {event.hostedBy}
                      </Link>
                    </strong>
                  </p>
                </Item.Content>
              </Item>
            </Item.Group>
          </Segment>
        </Segment>

        <Segment clearing attached='bottom'>
          {!isHost && (
            <>
              {isGoing ? (
                <Button onClick={handleLeaveEvent} loading={loading}>
                  {t('eventAttendCancel')}
                </Button>
              ) : (
                <Button color='teal' loading={loading} onClick={authenticated ? handleJoinEvent : () => setModalOpen(true)}>
                  {t('eventAttendJoin')}
                </Button>
              )}
            </>
          )}
          {isHost && (
            <Button
              color='orange'
              floated='right'
              as={Link}
              to={`/events/manage/${event.id}`}
            >
              {t('eventManage')}
            </Button>
          )}
        </Segment>
      </Segment.Group>
    </>
  );
}
