import React from "react";
import { useSelector } from "react-redux";
import LoginForm from "../../../features/auth/LoginForm";
import RegisterForm from "../../../features/auth/RegisterForm";
import DeleteAccountForm from "../../../features/auth/DeleteAccountForm";

export default function ModalManager() {
  const modalLookup = { LoginForm, RegisterForm, DeleteAccountForm };
  const currentModal = useSelector((state) => state.modals);
  let renderModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderModal = <ModalComponent {...modalProps} />;
  }

  return <span>{renderModal}</span>;
}
