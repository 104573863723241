export const pricingData = [
  {
    id: 1,
    tier: 'Free',
    monthly: '$0',
    yearly: '$0',
    benefits: [
      'Limited to hosting 5 active events',
      'Limited to 5 attendees per hosted event',
      'Past/Cancelled hosted events deleted after 30 days',
      'Public events only',
      "Can't remove an attendee from an event",
      'Can join any public event',
      'Can join any private event invited to',
    ],
    benefits_es: [
      'Limitado a albergar 5 eventos activos',
      'Limitado a 5 asistentes por evento organizado',
      'Eventos alojados pasados/cancelados eliminados después de 30 días',
      'Solo eventos públicos',
      'No se puede eliminar a un asistente de un evento',
      'Puede unirse a cualquier evento público',
      'Puede unirse a cualquier evento privado invitado',
    ],
    notes: [],
    notes_es: []
  },
  {
    id: 2,
    tier: 'Premium',
    monthly: '$10 *',
    yearly: '$100 *',
    benefits: [
      'Unlimited active events',
      'Unlimited attendees per event **',
      'Can limit the number of attendees for any event',
      'Can host private or public events',
      'Can remove attendees from any hosted event',
      'Can create a group to host events',
      'Can create a recurring event',
    ],
    benefits_es: [
      'Eventos activos ilimitados',
      'Asistentes ilimitados por evento **',
      'Puede limitar el número de asistentes para cualquier evento',
      'Puede albergar eventos públicos o privados',
      'Puede eliminar asistentes de cualquier evento organizado',
      'Puede crear un grupo para organizar eventos',
      'Puede crear un evento recurrente',
    ],
    notes: [
      '* you will be charged your local currency based on the exchange rate to USD at the time of purchase',
      '** attendees limited to 100 by default',
    ],
    notes_es: [
      '* se le cobrará en su moneda local según el tipo de cambio a USD en el momento de la compra',
      '** asistentes limitados a 100 por defecto',
    ]
  }
]