import {Grid, Loader} from 'semantic-ui-react';
import EventList from './EventList';

import {useDispatch, useSelector} from 'react-redux';
import EventListItemPlaceholder from './EventListItemPlaceholder';
import EventFilters from './EventFilters';
import {fetchEvents} from '../eventActions';
import {useEffect, useState} from 'react';
import EventsFeed from "./EventsFeed";
import {RETAIN_STATE} from "../eventConstants";


export default function EventDashboard() {
  const dispatch = useDispatch();

  const limit = 10;

  const {
    events,
    moreEvents,
    filter,
    category,
    cityFilter,
    venueFilter,
    hostFilter,
    startDate,
    lastVisible,
    retainState
  } = useSelector((state) => state.event);
  const {loading} = useSelector((state) => state.async);
  const {authenticated} = useSelector((state) => state.auth);

  const [loadingInitial, setLoadingInitial] = useState(false);

  let eventsFiltered = events;

  if (cityFilter && cityFilter.city.address !== '') {
    eventsFiltered = eventsFiltered.filter(event => event.city.address === cityFilter.city.address);
  }
  if (venueFilter !== '') {
    eventsFiltered = eventsFiltered.filter(event => event.venue.address === venueFilter);
  }
  if (hostFilter !== '') {
    eventsFiltered = eventsFiltered.filter(event => event.hostedBy.includes(hostFilter));
  }
  if (category && category !== '') {
    eventsFiltered = eventsFiltered.filter(event => event.category === category);
  }

    useEffect(() => {
      if (retainState) return;
      setLoadingInitial(true);
      dispatch(fetchEvents(filter, startDate, limit)).then(() => {
        setLoadingInitial(false);
      });
      return () => {
        dispatch({type: RETAIN_STATE});
      }
    }, [dispatch, filter, startDate, retainState]);

  function handleFetchNextEvents() {
    dispatch(fetchEvents(filter, startDate, limit, lastVisible));
  }

  return (
    <Grid>
      <Grid.Column width={10}>
        {loadingInitial && (
          <>
            <EventListItemPlaceholder/>
            <EventListItemPlaceholder/>
          </>
        )}
        <EventList events={eventsFiltered} getNextEvents={handleFetchNextEvents} loading={loading}
                   moreEvents={moreEvents}/>
      </Grid.Column>
      <Grid.Column width={6}>
        {authenticated &&
          <EventsFeed/>
        }
        <EventFilters
          loading={loading}
        />
      </Grid.Column>
      <Grid.Column width={10}>
        <Loader active={loading}/>
      </Grid.Column>
    </Grid>
  );
}
