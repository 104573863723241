import {Item} from "semantic-ui-react";

export default function PersonalData() {
	return (
		<>
			<Item>
				<Item.Header style={{marginBottom: 5}} as='h3'>Personal Data</Item.Header>
			</Item>
			<Item>
				<Item.Description>
					While using Our Service, We may ask You to provide Us with certain personally identifiable information that
					can be used to contact or identify You. Personally identifiable information may include, but is not
					limited to:<br/><br/>

					<ul>
						<li>Email address</li>

						<li>Usage Data</li>
					</ul>
				</Item.Description>
			</Item>
		</>
	)
}