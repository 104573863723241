import React, {useEffect, useState} from 'react';
import {
    Button,
    Divider,
    Grid,
    Header,
    Item,
    Reveal,
    Segment,
    Statistic,
} from 'semantic-ui-react';
import {toast} from "react-toastify";
import {followUser, getFollowingDoc, unfollowUser} from "../../../app/firestore/firestoreService";
import {useDispatch, useSelector} from "react-redux";
import {setFollowUser, setUnfollowUser} from "../profileActions";
import {CLEAR_FOLLOWINGS} from "../profileConstants";
import {useTranslation} from "react-i18next";

export default function ProfileHeader({profile, isCurrentUser}) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const {followingUser} = useSelector((state) => state.profile);

    const {t} = useTranslation();

    useEffect(() => {
        if (isCurrentUser) return;
        setLoading(true);

        async function fetchFollowingDoc() {
            try {
                const followingDoc = await getFollowingDoc(profile.id);
                if (followingDoc && followingDoc.exists) {
                    dispatch(setFollowUser());
                }
            } catch (error) {
                toast.error(error.message);
            }
        }

        fetchFollowingDoc().then(() => setLoading(false));
        return () => {
            dispatch({type: CLEAR_FOLLOWINGS});
        }
    }, [isCurrentUser, profile, dispatch])


    async function handleFollowUser() {
        setLoading(true);
        try {
            await followUser(profile);
            dispatch(setFollowUser())
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    }

    async function handleUnfollowUser() {
        setLoading(true);
        try {
            await unfollowUser(profile);
            dispatch(setUnfollowUser())
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Segment>
            <Grid>
                <Grid.Column width={12}>
                    <Item.Group>
                        <Item>
                            <Item.Image
                                avatar
                                size='small'
                                src={profile.photoURL || '/assets/user.png'}
                            />
                            <Item.Content verticalAlign='middle'>
                                <Header
                                    as='h1'
                                    style={{display: 'block', marginBottom: 10}}
                                    content={profile.displayName}
                                />
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Statistic.Group>
                        <Statistic label={t('followers')} value={profile.followersCount || 0}/>
                        <Statistic label={t('following')} value={profile.followingCount || 0}/>
                    </Statistic.Group>
                    {!isCurrentUser && (
                        <>
                            <Divider/>
                            <Reveal animated='move'>
                                <Reveal.Content visible style={{width: '100%'}}>
                                    <Button fluid color='teal' content={followingUser ? t('following') : t('notFollowing')}/>
                                </Reveal.Content>
                                <Reveal.Content hidden style={{width: '100%'}}>
                                    <Button fluid color={followingUser ? 'red' : 'green'}
                                            content={followingUser ? t('unfollow') : t('follow')} loading={loading} basic
                                            onClick={followingUser ? () => handleUnfollowUser() : () => handleFollowUser()}/>
                                </Reveal.Content>
                            </Reveal>
                        </>
                    )}
                </Grid.Column>
            </Grid>
        </Segment>
    );
}
