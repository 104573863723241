import {Item} from "semantic-ui-react";
import React from "react";
import {Link} from "react-router-dom";

export default function ProccessingPersonal() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h1'>Detailed Information on the Processing of Your Personal
          Data</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          The Service Providers We use may have access to Your Personal Data. These third-party vendors collect, store,
          use, process and transfer information about Your activity on Our Service in accordance with their Privacy
          Policies.
        </Item.Description>
      </Item>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h2'>Usage, Performance and Miscellaneous</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          We may use third-party Service Providers to provide better improvement of our Service.<br/><br/>

          <ul>
            <li><strong>Google Places</strong><br/><br/>

              Google Places is a service that returns information about places using HTTP requests. It is operated by
              Google<br/><br/>

              Google Places service may collect information from You and from Your Device for security
              purposes.<br/><br/>

              The information gathered by Google Places is held in accordance with the Privacy Policy of
              Google: <Link
                to='https:////www.google.com/intl/en/policies/privacy/'>https:////www.google.com/intl/en/policies/privacy/</Link><br/><br/>
            </li>
            <li><strong>Firebase</strong><br/><br/>

              Their Privacy Policy can be viewed at <Link
                to='https://firebase.google.com/support/privacy'>https://firebase.google.com/support/privacy</Link>
            </li>
          </ul>
        </Item.Description>
      </Item>

    </>
  )
}