import {Item} from "semantic-ui-react";

export default function TermsDisputes() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h1'>Disputes Resolution</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally
          by contacting the Company.<br/><br/>
        </Item.Description>
      </Item>
    </>
  )
}