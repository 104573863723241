import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Header, Segment } from "semantic-ui-react";
import {useTranslation} from "react-i18next";

export default function ErrorComponent() {
    const {error} = useSelector((state) => state.async);

    const {t} = useTranslation();

    return (
        <Segment placeholder>
            <Header textAlign='center' content={error?.message || t('errorHeader')} />
            <Button as={Link} to='/events' primary style={{marginTop: 20}} content={t('errorBtn')} />
        </Segment>
    )
}