import {Container, Header, Item, Segment} from "semantic-ui-react";
import React from "react";
import {Link} from "react-router-dom";
import InterpretationDefinitions from "../subHeadings/InterpretationDefinitions";
import TermsHeader from "./TermsHeader";
import TermsAcknowledge from "./TermsAcknowledge";
import LinksOtherWeb from "../subHeadings/LinksOtherWeb";
import TermsAccounts from "./TermsAccounts";
import TermsSubscription from "./TermsSubscription";
import TermsUpload from "./TermsUpload";
import TermsTermination from "./TermsTermination";
import TermsLiability from "./TermsLiability";
import TermsAsIs from "./TermsAsIs";
import TermsGoverning from "./TermsGoverning";
import TermsDisputes from "./TermsDisputes";
import TermsEU from "./TermsEU";
import TermsUSA from "./TermsUSA";
import TermsSeverability from "./TermsSeverability";
import {TermsTranslation} from "./TermsTranslation";
import {TermsChanges} from "./TermsChanges";
import {TermsContact} from "./TermsContact";

export default function TermsConditions() {
	return (
		<>
			<Container>
				<Segment.Group>
					<Segment
						textAlign='center'
						attached='top'
						inverted
						color='teal'
						style={{border: "none"}}
					>
						<Header>ExpatEvents Terms and Conditions</Header>
					</Segment>
					<Segment attached>
						<TermsHeader />
						<InterpretationDefinitions />
						<TermsAcknowledge />
						<LinksOtherWeb />
						<TermsAccounts />
						<TermsSubscription />
						<TermsUpload />
						<TermsTermination />
						<TermsLiability />
						<TermsAsIs />
						<TermsGoverning />
						<TermsDisputes />
						<TermsEU />
						<TermsUSA />
						<TermsSeverability />
						<TermsTranslation />
						<TermsChanges />
						<TermsContact />
					</Segment>
					<Segment attached='bottom' textAlign='center'>
						<Item>
							<Item.Meta>This policy has been created with the help of the <Link
								to='https://www.freeprivacypolicy.com/free-privacy-policy-generator/'>Free Privacy Policy
								Generator</Link></Item.Meta>
							<Item.Description>Last updated: May 8, 2023</Item.Description>
						</Item>
					</Segment>
				</Segment.Group>
			</Container>
		</>
	)
}