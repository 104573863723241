import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Image, Dropdown } from "semantic-ui-react";
import { toast } from "react-toastify";
import { signOutFirebaseUser } from "../../app/firestore/firebaseService";
import {useTranslation} from "react-i18next";

export default function SignedInMenu() {
  const navigate = useNavigate();
  const { currentUserProfile } = useSelector((state) => state.profile);

  const {t} = useTranslation();

  async function handleSignout() {
    try {
      signOutFirebaseUser();
      navigate("/");
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Menu.Item position='right'>
      <Image
        avatar
        spaced='right'
        src={currentUserProfile?.photoURL || "/assets/user.png"}
      />
      <Dropdown pointing='top left' text={currentUserProfile?.displayName}>
        <Dropdown.Menu>
          <Dropdown.Item
            as={Link}
            to='/events/new'
            text={t('create')}
            icon='plus'
          />
          <Dropdown.Item text={t('profilemy')} icon='user' as={Link} to={`/profile/${currentUserProfile?.id}`} />
          <Dropdown.Item text={t('accountmy')} as={Link} to='/account' icon='settings' />
          <Dropdown.Item text={t('feedback')} as={Link} to='/feedback' icon='mail' />
          <Dropdown.Item text={t('signout')} icon='power' onClick={handleSignout} />
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  );
}
