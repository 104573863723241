import React, {useState} from 'react';
import {Tab} from 'semantic-ui-react';
import AboutTab from './AboutTab';
import PhotosTab from './PhotosTab';
import EventsTab from './EventsTab';

import FollowingTab from "./FollowingTab";
import {useTranslation} from "react-i18next";

export default function ProfileContent({profile, isCurrentUser}) {

    const [activeTab, setActiveTab] = useState(0);

    const {t} = useTranslation();

    const panes = [
        {
            menuItem: t('about'), //0
            render: () => (
                <AboutTab profile={profile} isCurrentUser={isCurrentUser}/>
            ),
        },
        {
            menuItem: t('photos'), //1
            render: () => (
                <PhotosTab profile={profile} isCurrentUser={isCurrentUser}/>
            ),
        },
        {menuItem: t('events'), render: () => <EventsTab profile={profile}/>}, //2
        {menuItem: t('followers'), render: () => <FollowingTab profile={profile} activeTab={activeTab} key={profile.id}/>}, //3
        {menuItem: t('following'), render: () => <FollowingTab profile={profile} activeTab={activeTab} key={profile.id}/>}, //4
    ];

    return (
        <Tab
            menu={{fluid: true, vertical: true}}
            menuPosition='right'
            panes={panes}
            onTabChange={(e, data) => setActiveTab(data.activeIndex)}
        />
    );
}
