import {Grid, Header, Image} from "semantic-ui-react";
import React from "react";
import {useTranslation} from "react-i18next";

export default function GroupsComponent() {

	const {t} = useTranslation();

	return (
		<Grid container stackable verticalAlign='middle'>
			<Grid.Row>
				<Grid.Column width={8}>
					<Header as='h3' style={{fontSize: '2em'}}>
						{t('group1Header')}
					</Header>
					<p style={{fontSize: '1.33em'}}>{t('group1Text')}</p>
					<Header as='h3' style={{fontSize: '2em'}}>
						{t('group2Header')}
					</Header>
					<p style={{fontSize: '1.33em'}}>{t('group2Text')}</p>
					<Header as='h3' style={{fontSize: '2em'}}>
						{t('group3Header')}
					</Header>
					<p style={{fontSize: '1.33em'}}>{t('group3Text')}</p>
					<ul style={{fontSize: '1.33em'}}>
						<li>{t('group3Item1')}</li>
						<li>{t('group3Item2')}</li>
						<li>{t('group3Item3')}</li>
						<li>{t('group3Item4')}</li>
						<li>{t('group3Item5')}</li>
						<li>{t('group3Item6')}</li>
					</ul>
				</Grid.Column>
				<Grid.Column width={6}>
					<Image bordered size='large' src='/assets/categoryImages/culture.jpg'/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	)
}