import {Item} from "semantic-ui-react";

export default function ThirdParty() {
	return (
		<>
			<Item>
				<Item.Header style={{marginBottom: 5}} as='h3'>Information from Third-Party Social Media Services</Item.Header>
			</Item>
			<Item>
				<Item.Description>
					The Company allows You to create an account and log in to use the Service through the following Third-party
					Social Media Services:<br/><br/>

					<ul>
						<li>Google</li>
						<li>Facebook</li>
						<li>Apple</li>
					</ul><br/>


					If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may
					collect Personal data that is already associated with Your Third-Party Social Media Service's account, such
					as Your name, Your email address, Your activities or Your contact list associated with that account.<br/><br/>

					You may also have the option of sharing additional information with the Company through Your Third-Party
					Social Media Service's account. If You choose to provide such information and Personal Data, during
					registration or otherwise, You are giving the Company permission to use, share, and store it in a
					manner consistent with this Privacy Policy.

				</Item.Description>
			</Item>
		</>
	)
}