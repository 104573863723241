import {useTranslation} from "react-i18next";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import {Form, Formik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {Button, Header, Label} from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import React from "react";
import {deleteUserRequest} from "../../app/firestore/firebaseService";
import {toast} from "react-toastify";
import {closeModal} from "../../app/common/modals/modalReducer";
import {useNavigate} from "react-router-dom";

export default function DeleteAccountForm() {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const {currentUserProfile} = useSelector((state) => state.profile);





    const {t} = useTranslation();



    return (
        <ModalWrapper size='small' header={t('deleteAccountHeader')}>
			<p>{t('deleteAccountP')}</p>
            <ul>
                <li>{t('deleteAccountItem1')}</li>
                <li>{t('deleteAccountItem2')}</li>
                <li>{t('deleteAccountItem3')}</li>
                <li>{t('deleteAccountItem4')}</li>
                <li>{t('deleteAccountItem5')}</li>
                <li>{t('deleteAccountItem6')}</li>
                <li>{t('deleteAccountItem7')}</li>
            </ul>
            <Formik
                initialValues={{displayName: ''}}
                onSubmit={async (values, {setSubmitting, }) => {
                    try{
                        await deleteUserRequest(currentUserProfile)
                        setSubmitting(false);
                        navigate('/');
                        dispatch(closeModal());
                    } catch (error) {
                        toast.error(error.message);
                        setSubmitting(false);
                    }

                }}
                validationSchema={Yup.object({
                    displayName: Yup.string().required().oneOf([currentUserProfile.displayName, null], t('deleteAccountRequired'))
                })}
            >
				{({isSubmitting, isValid, dirty, errors}) => (
					<Form className='ui form'>
                        <Header as='h4' textAlign='center' content={t('deleteAccountBody')}/>
                        <MyTextInput name='displayName' placeholder={t('displayNameHandle')} />
                        {errors.auth && (
                            <Label
                                basic
                                color='red'
                                style={{marginBottom: 10}}
                                content={errors.auth}
                            />
                        )}
                        <Button
                            loading={isSubmitting}
                            disabled={!isValid || !dirty || isSubmitting}
                            type='submit'
                            size='large'
                            color='teal'
                            content={t('delete')}
                        />
					</Form>
				)}
            </Formik>

        </ModalWrapper>
    )
}