import {Item} from "semantic-ui-react";

export default function TermsSubscription() {
	return (
		<>
			<Item>
				<Item.Header style={{marginBottom: 5}} as='h1'>Subscriptions</Item.Header>
			</Item>
			<Item>
				<Item.Description>
					You may as part of your account purchase a <strong>Subscription</strong> to ExpatEvents for additional
					features and benefits.  You are not required to purchase a subscription to use ExpatEvents or to join
					an event hosted by someone else.  If you purchase a subscription you are agreeing to the following terms.<br/><br/>

					<strong>Third Party Payment Processor:</strong> We use a third party payment processor to process payments
					four our subscriptions.  That party currently is <strong>Stripe</strong>.  We do not retain any payment
					information other than a token provided by the processor to verify your payment.<br/><br/>

					<strong>Monthly Subscription:</strong> By purchasing a monthly subscription, you agree to an initial <strong>
					and recurring monthly subscription fee</strong> at the then-current monthly subscription rate, and you accept
					responsibility for all recurring charges until you cancel your subscription.  You may cancel you monthly
					subscription at any time.<br/><br/>

					<b>AUTOMATIC MONTHLY RENEWAL TERMS: Once you subscribe you will receive the benefits of a paying member.
					ExpatEvents will continue to automatically process your monthly subscription fee each month at the then
					current monthly subscription rate, until you cancel your subscription. You can cancel your subscription
					on your My Account Page.</b><br/><br/>

					<strong>Annual Subscription:</strong> By purchasing an annual subscription, you agree to an initial <strong>
					and recurring annual subscription fee</strong> at the then-current annual subscription rate, and you accept
					responsibility for all recurring charges until you cancel your subscription.  You may cancel you annual
					subscription at any time before your anniversary date.<br/><br/>

					<b>AUTOMATIC ANNUAL RENEWAL TERMS: Once you subscribe you will receive the benefits of a paying member.
						ExpatEvents will continue to automatically process your annual subscription fee each year on your initial
						subscription date at the then	current annual subscription rate, until you cancel your subscription. You can
						cancel your subscription on your My Account Page.</b><br/><br/>
				</Item.Description>
			</Item>
		</>
	)
}

