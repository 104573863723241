import {Item} from "semantic-ui-react";
import {Link} from "react-router-dom";

export default function ContactPrivacy() {
	return (
		<>
			<Item>
				<Item.Header style={{marginBottom: 5}} as='h1'>Contact Us</Item.Header>
			</Item>
			<Item>
				<Item.Description>
					If you have any questions about this Privacy Policy, You can contact us:<br/><br/>
					<ul>
						<li>By email: support@expatevents.net</li>

						<li>By visiting this page on our website: <Link	to={'/feedback'}>https://www.ExpatEvents.net/feedback</Link></li>
					</ul>
				</Item.Description>
			</Item>
		</>
	)
}