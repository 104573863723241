import React from 'react';
import Calendar from 'react-calendar';
import {Header, Menu} from 'semantic-ui-react';
import {useDispatch, useSelector} from "react-redux";
import {setFilter, setStartDate} from "../eventActions";
import {useTranslation} from "react-i18next";
import CityFilterForm from "../filterForm/CityFilterForm";
import VenueFilterForm from "../filterForm/VenueFilterForm";
import HostFilterForm from "../filterForm/HostFilterForm";
import {CategoryFilterForm} from "../filterForm/CategoryFilterForm";

export default function EventFilters({loading}) {
  const {filter, startDate} = useSelector((state) => state.event);
  const {authenticated} = useSelector((state) => state.auth);

  const {t} = useTranslation();

  const dispatch = useDispatch();

  return (
    <>
      <Menu vertical size='large' style={{width: '100%'}}>
        <Header icon='filter' attached color='teal' content={t('filters')}/>
        <Menu.Item
          content={t('allEvents')}
          active={filter === 'all'}
          onClick={() => dispatch(setFilter('all'))}
          disabled={loading}
        />
        {authenticated &&
          <>
            <Menu.Item
              content={t('goingFilter')}
              active={filter === 'isGoing'}
              onClick={() => dispatch(setFilter( 'isGoing'))}
              disabled={loading}
            />
            <Menu.Item
              content={t('hostingFilter')}
              active={filter === 'isHosting'}
              onClick={() => dispatch(setFilter( 'isHosting'))}
              disabled={loading}
            />
          </>
        }
        <Header sub attached color='blue' content={t('subFilters')} />
        <Menu.Item>
          <CityFilterForm />
        </Menu.Item>
        <Menu.Item>
          <VenueFilterForm />
        </Menu.Item>
        <Menu.Item>
          <HostFilterForm />
        </Menu.Item>
        <Menu.Item>
          <CategoryFilterForm />
        </Menu.Item>
      </Menu>

      <Header icon='calendar' attached color='teal' content={t('selectDate')}/>
      <Calendar
        onChange={(date) => dispatch(setStartDate(date))}
        value={startDate || new Date()}
        tileDisabled={() => loading}
      />
    </>
  );
}
