import { Container } from 'semantic-ui-react';
import NavBar from '../../features/nav/NavBar';
import EventDashboard from '../../features/events/eventsDashboard/EventDashboard';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from '../../features/home/HomePage';
import EventDetailedPage from '../../features/events/eventDetailed/EventDetailed';
import EventForm from '../../features/events/eventForm/EventForm';
import ModalManager from '../common/modals/ModalManager';
import { ToastContainer } from 'react-toastify';
import ErrorComponent from '../common/errors/ErrorComponent';
import AccountPage from '../../features/auth/AccountPage';
import ProfilePage from '../../features/profiles/profilePage/ProfilePage';
import PrivateRoute from "./PrivateRoute";
import PrivacyPolicy from "../../features/privacy/PrivacyPolicy";
import TermsConditions from "../../features/privacy/terms/TermsConditions";
import FooterComponent from "../../features/footer/FooterComponent";
import FeedbackForm from "../../features/feedback/feedbackForm/FeedbackForm";
import {Suspense} from "react";


function App() {
  const { key } = useLocation();


  function EventsLayout() {
    return (
      <>
        <NavBar />
        <Container className='main'>
          <Outlet />
        </Container>
        <FooterComponent />
      </>
    );
  }

  return (
    <Suspense fallback="Loading...">
      <ModalManager />
      <ToastContainer position='bottom-right' theme='colored' hideProgressBar />
      <Routes>
        <Route path='/' element={<HomePage />} />

        <Route path='/events' element={<EventsLayout />}>
          <Route path='' element={<EventDashboard />} />
          <Route path=':id' element={<EventDetailedPage />} />
          <Route path='new' element={<PrivateRoute><EventForm key={key} /></PrivateRoute>} />
          <Route path='manage/:id' element={<PrivateRoute><EventForm /></PrivateRoute>} />
        </Route>
        <Route path='/error' element={<ErrorComponent />} />
        <Route path='/account' element={<PrivateRoute><EventsLayout /></PrivateRoute>}>
          <Route path='' element={<AccountPage />} />
        </Route>
        <Route path='/profile' element={<PrivateRoute><EventsLayout /></PrivateRoute>}>
          <Route path=':id' element={<ProfilePage />} />
        </Route>
        <Route path='/privacy' element={<EventsLayout />}>
          <Route path='' element={<PrivacyPolicy />} />
        </Route>
        <Route path='/terms' element={<EventsLayout />}>
          <Route path='' element={<TermsConditions />} />
        </Route>
        <Route path='/feedback' element={<EventsLayout />}>
          <Route path='' element={<FeedbackForm />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
