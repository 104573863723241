
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import * as Yup from 'yup';
import {Button, Header, Segment} from "semantic-ui-react";
import {Form, Formik} from "formik";
import React from "react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import {addFeedbackToFirestore} from "../../../app/firestore/firestoreService";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

export default function FeedbackForm() {
	const {currentUser} = useSelector((state) => state.auth);
	const {error} = useSelector((state) => state.async);

	const {t} = useTranslation();

	let navigate = useNavigate();

	const initialValues = {
		email: currentUser?.email || "",
		name: currentUser?.displayName || "",
		message: "",
	}

	const validationSchema = Yup.object({
		email: Yup.string().required(t('emailRequired')),
		message: Yup.string().required(t('feedbackRequired')),
	});

	if (error) return navigate("/error");

	return (
		<Segment clearing>
			<Formik
				initialValues={initialValues}
				onSubmit={async (values, {setSubmitting}) => {
					try {
						await addFeedbackToFirestore(values);
						toast.success(t('thankFeedbackToast'));
						navigate("/events");
					} catch (error) {
						toast.error(error.message);
					} finally {
						setSubmitting(false);
					}
				}}
				validationSchema={validationSchema}>
				{({isSubmitting, dirty, isValid}) => (
					<Form className='ui form'>
						<Header sub color='teal' content={t('feedbackFormHeader')}/>
						<MyTextInput name='email' placeholder={t('email')}/>
						<MyTextInput name='name' placeholder={t('name')}/>
						<MyTextArea name='message' placeholder={t('feedback')} rows={3}/>
						<Button
							type='submit'
							floated='right'
							positive
							content={t('submit')}
							loading={isSubmitting}
							disabled={!isValid || !dirty || isSubmitting}
						/>
					</Form>
					)}
			</Formik>
		</Segment>
	)
}