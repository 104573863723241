import {Item} from "semantic-ui-react";

export default function TermsUSA() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h1'>United States Legal Compliance</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          You represent and warrant that (i) You are not located in a country that is subject to the United States
          government embargo, or that has been designated by the United States government as a "terrorist supporting"
          country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.<br/><br/>
        </Item.Description>
      </Item>
    </>
  )
}