import {Container, Header, Item, Segment} from "semantic-ui-react";
import React from "react";
import PrivacyHeader from "./subHeadings/PrivacyHeader";
import InterpretationDefinitions from "./subHeadings/InterpretationDefinitions";
import CollectingUsing from "./subHeadings/CollectingUsing";
import ProccessingPersonal from "./subHeadings/ProccessingPersonal";
import ChildsPrivacy from "./subHeadings/ChildsPrivacy";
import LinksOtherWeb from "./subHeadings/LinksOtherWeb";
import ChangesPrivacy from "./subHeadings/ChangesPrivacy";
import ContactPrivacy from "./subHeadings/ContactPrivacy";
import {Link} from "react-router-dom";

export default function PrivacyPolicy() {
  return (
    <Container>
      <Segment.Group>
        <Segment
          textAlign='center'
          attached='top'
          inverted
          color='teal'
          style={{border: "none"}}
        >
          <Header>ExpatEvents Privacy Policy</Header>
        </Segment>
        <Segment attached>
          <Item.Group>
            <PrivacyHeader/>
            <InterpretationDefinitions/>
            <CollectingUsing/>
            <ProccessingPersonal/>
            <ChildsPrivacy/>
            <LinksOtherWeb/>
            <ChangesPrivacy/>
            <ContactPrivacy/>
          </Item.Group>
        </Segment>
        <Segment attached='bottom' textAlign='center'>
          <Item>
            <Item.Meta>This policy has been created with the help of the <Link
              to='https://www.freeprivacypolicy.com/free-privacy-policy-generator/'>Free Privacy Policy
              Generator</Link></Item.Meta>
            <Item.Description>Last updated: May 8, 2023</Item.Description>
          </Item>
        </Segment>
      </Segment.Group>
    </Container>
  )
}