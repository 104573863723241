import {Item} from "semantic-ui-react";

export default function TermsEU() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h1'>For European Union (EU) Users</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country
          in which you are resident in.<br/><br/>
        </Item.Description>
      </Item>
    </>
  )
}