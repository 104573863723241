import {Segment, Statistic} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import PriceItemsLang from "./PriceItemsLang";
import PriceNotesLang from "./PriceNotesLang";

export default function PricingComponent({price}) {
	const amt = price.monthly + ' / ' + price.yearly;

	const {t, i18n} = useTranslation();
	return (
		<>
		<Segment.Group raised key={price.id}>
			<Segment secondary attached='top' textAlign='center'>
				{price.tier === 'Free' ? (
					<Statistic label={t('forever')}  value={price.monthly}/>
				): (

						<Statistic label={t('monthYear')}  value={amt}  />
				)}
			</Segment>
			<PriceItemsLang lang={i18n.language} data={price} />

			{price.notes.length > 0 && (
				<Segment attached secondary textAlign='center'>{t('notes')}</Segment>
			)}
			{price.notes.length >0 &&
				<PriceNotesLang lang={i18n.language} data={price} />
			}


		</Segment.Group>
		</>
	)
}