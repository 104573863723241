import React, { useState } from 'react';
import { Card, Grid, Header, Image, Tab } from 'semantic-ui-react';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useFireStoreCollection from '../../../app/hooks/useFirestoreCollection';
import { getUserEventsQuery } from '../../../app/firestore/firestoreService';
import { listenToUserEvents } from '../profileActions';
import { format } from 'date-fns';
import {useTranslation} from "react-i18next";

export default function EventsTab({ profile }) {
  const [activeTab, setActiveTab] = useState(0);

  const { profileEvents } = useSelector((state) => state.profile);
  const { loading } = useSelector((state) => state.async);

  const {t} = useTranslation();

  const dispatch = useDispatch();

  const panes = [
    { menuItem: t('futureEvents'), pane: { key: 'future' } },
    { menuItem: t('pastEvents'), pane: { key: 'past' } },
    { menuItem: t('hosting'), pane: { key: 'hosting' } },
  ];

  useFireStoreCollection({
    query: () => getUserEventsQuery(activeTab, profile.id),
    data: (events) => dispatch(listenToUserEvents(events)),
    deps: [dispatch, activeTab, profile.id],
  });

  return (
    <Tab.Pane loading={loading}>
      <Grid>
        <Grid.Column width={16}>
          <Header floated='left' icon='calendar' content={t('events')} />
        </Grid.Column>
        <Grid.Column width={16}>
          <Tab
            onTabChange={(e, data) => setActiveTab(data.activeIndex)}
            panes={panes}
            menu={{ secondary: true, pointing: true }}
          />
          <Card.Group itemsPerRow={5} style={{ marginTop: 10 }}>
            {profileEvents.map((event) => (
              <Card as={Link} to={`/events/${event.id}`} key={event.id}>
                <Image
                  src={`/assets/categoryImages/${event.category}.jpg`}
                  style={{ minHeight: 100, objectFit: 'cover' }}
                />
                <Card.Content>
                  <Card.Header content={event.title} textAlign='center' />
                  <Card.Meta textAlign='center'>
                    <div>{format(event.date, 'dd MMM yyyy')}</div>
                    <div>{format(event.date, 'hh:mm a')}</div>
                  </Card.Meta>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  );
}
