import React, {useState} from "react";
import ModalWapper from "../../app/common/modals/ModalWrapper";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import {Button, Divider, Header, Label} from "semantic-ui-react";
import {useDispatch} from "react-redux";
import {closeModal} from "../../app/common/modals/modalReducer";
import {requestPasswordReset, signInWithEmail} from "../../app/firestore/firebaseService";
import SocialLogin from "./SocialLogin";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export default function LoginForm() {
  const dispatch = useDispatch();

  const [passwordReset, setPasswordReset] = useState(false);

  const {t} = useTranslation();

  function showPasswordReset() {
    setPasswordReset(true);
  }

  function hidePasswordReset() {
    setPasswordReset(false);
  }

  return (
    <ModalWapper size='mini' header={passwordReset
      ? t('resetTitle') : t('signInTo')}>
      {passwordReset ? (
        <Formik
          initialValues={{email: ""}}
          validationSchema={Yup.object({
            email: Yup.string().required().email(),
          })}
          onSubmit={async (values, {setSubmitting, setErrors}) => {
            try {
              await requestPasswordReset(values);
              setSubmitting(false);
              dispatch(closeModal());
            } catch (error) {
              setErrors({auth: t('resetProblem')});
              setSubmitting(false);
            }
          }}
        >
          {({isSubmitting, isValid, dirty, errors}) => (
            <Form className='ui form'>
              <Header as='h4' textAlign='center' content={t('resetBody')}/>
              <MyTextInput name='email' placeholder={t('email')}/>
              {errors.auth && (
                <Label
                  basic
                  color='red'
                  style={{marginBottom: 10}}
                  content={errors.auth}
                />
              )}
              <Button.Group>
                <Button
                  loading={isSubmitting}
                  disabled={!isValid || !dirty || isSubmitting}
                  type='submit'
                  size='large'
                  color='teal'
                  content={t('resetRequest')}
                />

                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  size='large'
                  content={t('cancel')}
                  onClick={hidePasswordReset}
                />
              </Button.Group>
            </Form>
          )}
        </Formik>

      ) : (
        <Formik
          initialValues={{email: "", password: ""}}
          validationSchema={Yup.object({
            email: Yup.string().required().email(),
            password: Yup.string().required(),
          })}
          onSubmit={async (values, {setSubmitting, setErrors}) => {
            try {
              await signInWithEmail(values);
              setSubmitting(false);
              dispatch(closeModal());
            } catch (error) {
              setErrors({auth: t('emailInvalid')});
              setSubmitting(false);
            }
          }}
        >
          {({isSubmitting, isValid, dirty, errors}) => (
            <Form className='ui form'>
              <MyTextInput name='email' placeholder={t('email')}/>
              <MyTextInput
                name='password'
                placeholder={t('password')}
                type='password'
              />
              <p>{t('forgotPassword')} <Button as={Link} content={t('passwordReset')}
                                               onClick={showPasswordReset}/></p>
              {errors.auth && (
                <Label
                  basic
                  color='red'
                  style={{marginBottom: 10}}
                  content={errors.auth}
                />
              )}

              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type='submit'
                fluid
                size='large'
                color='teal'
                content={t('login')}
              />

              <Divider horizontal>{t('or')}</Divider>
              <SocialLogin disabled={false}/>

            </Form>
          )}
        </Formik>
      )}
    </ModalWapper>
  )
    ;
}
