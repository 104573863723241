import {Segment} from "semantic-ui-react";

export default function PriceNotesLang({lang, data}) {
	switch (lang) {
		case 'es': {
			return data.notes_es.map((item, index) => (
				<Segment attached textAlign='center' key={index}><p>{item}</p></Segment>
			));
		}
		case 'en':
		default: {
			return data.notes.map((item, index) => (
				<Segment attached textAlign='center' key={index}><p>{item}</p></Segment>
			));
		}
	}
}