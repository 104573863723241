import {useField} from "formik";
import {Checkbox, FormField, Label} from "semantic-ui-react";
import React from "react";

export default function MyCheckBox({ myLabel, ...props }) {

	const [field, meta, helpers] = useField(props);

	return (
		<FormField error={meta.touched && !!meta.error}>
			<Checkbox
				checked = {field.value || false}
				onChange={(e, d) => { helpers.setValue(d.checked)}}
				onBlur = {() => helpers.setTouched(true)}
				{...props}/>
			{meta.touched && meta.error ? (
				<Label basic color='red'>
					{meta.error}
				</Label>
			) : null}
		</FormField>
	)
}