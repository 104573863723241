import {Segment} from "semantic-ui-react";

export default function PriceItemsLang({lang, data}) {

  switch (lang) {
    case 'es': {
      return data.benefits_es.map((item, index) => (
        <Segment attached textAlign='center' key={index}><p>- {item}</p></Segment>
      ));
    }
    case 'en':
    default: {
      return data.benefits.map((item, index) => (
        <Segment attached textAlign='center' key={index}><p>- {item}</p></Segment>
      ));
    }
  }

}
