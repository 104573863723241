import {Item} from "semantic-ui-react";
import React from "react";

export default function PrivacyHeader() {
	return (
		<>
			<Item>
				<Item.Header style={{marginBottom: 5}} as='h1'>Privacy Policy</Item.Header>
			</Item>
			<Item>
					<Item.Description>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure
						of Your information when You use the Service and tells You about Your privacy rights and how the
						law protects You. <br/><br/>  We use Your Personal data to provide and improve the Service. By using
						the Service, You agree to the collection and use of information in accordance with this Privacy
						Policy.</Item.Description>

			</Item>
		</>
	)
}