import {Link} from "react-router-dom";
import {Feed} from "semantic-ui-react";
import {formatDistance} from "date-fns";
import {useTranslation} from "react-i18next";

export default function EventFeedItem({post}) {

  const {t} = useTranslation();

  let summary;
  switch (post.code) {
    case 'joined-event':
      summary = (
        <>
          <Link to={`/profile/${post.userUid}`}>{post.displayName}</Link> {t('newsFeedSignup')} <Link
          to={`/events/${post.eventId}`}>{post.title}</Link>
        </>
      )
      break;
    case 'left-event':
      summary = (
        <>
          <Link to={`/profile/${post.userUid}`}>{post.displayName}</Link> {t('newsFeedCancel')} < Link
          to={`/events/${post.eventId}`}>{post.title}</Link>
        </>
      )
      break;
    default:
      summary = 'Something happened'
      break;
  }
  return (
    <Feed.Event>
      <Feed.Label image={post?.photoURL || '/assets/user.png'} />
      <Feed.Content>
        <Feed.Date>{formatDistance(new Date(post.date), new Date())} ago</Feed.Date>
        <Feed.Summary>{summary}</Feed.Summary>
      </Feed.Content>
    </Feed.Event>
  )
}