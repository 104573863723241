import {Flag} from "semantic-ui-react";
import React from "react";

export default function LanguageItem({lang}) {

	let data;

	switch (lang) {
		case 'es':
			data = {flag: 'es', text: 'Español'};
			break;
		case 'en':
		default:
			data = {flag: 'us', text: 'English'};
	}


	return (
		<>
			<Flag name={data.flag} /> {data.text}
		</>
	)
}