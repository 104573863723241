import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { asyncActionError, asyncActionFinish, asyncActionStart} from "../async/asyncReducer";
import { dataFromSnapshot } from "../firestore/firestoreService";
import { onSnapshot } from '@firebase/firestore';

export default function useFireStoreDoc({query, data, deps, shouldExecute = true}) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!shouldExecute) return;
        dispatch(asyncActionStart());
        const unsubscribe = onSnapshot(query(),
            snapshot => {
                if(!snapshot.exists) {
                    dispatch(asyncActionError({code: 'not-found', message: 'Could not find event'}));
                    return;
                }
                data(dataFromSnapshot(snapshot));
                dispatch(asyncActionFinish());
            },
            () => dispatch(asyncActionError())
        );
        return () => unsubscribe();
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}