import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from "react-i18next";

import translateEN from './locales/en/translate.json';
import translateES from './locales/es/translate.json';

const resources = {
  en: {
    translation: translateEN
  },
  es: {
    translation: translateES
  }
}

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  // Disable before production
  debug: true,
  // TODO: Add namespaces
  //ns: ["home", "events", "account"],
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    wait: true,
  },
});

export default i18n;