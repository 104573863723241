import {Item} from "semantic-ui-react";

export default function ChangesPrivacy() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h1'>Changes to this Privacy Policy</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new
          Privacy Policy on this page.<br/><br/>

          We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
          effective and update the "Last updated" date at the top of this Privacy Policy.<br/><br/>

          You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
          effective when they are posted on this page.
        </Item.Description>
      </Item>
    </>
  )
}