import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { closeModal } from '../../app/common/modals/modalReducer';
import { socialLogin } from '../../app/firestore/firebaseService';
import {useTranslation} from "react-i18next";

export default function SocialLogin({disabled}) {
  const dispatch = useDispatch();

  const {t} = useTranslation();

  function handleSocialLogin(provider) {
    dispatch(closeModal());
    socialLogin(provider);
  }

  return (
    <>
      {/* TODO: Add this back in when Facebook authentication is approved by facebook. */}
      {/*<Button*/}
      {/*  icon='facebook'*/}
      {/*  fluid*/}
      {/*  color='facebook'*/}
      {/*  style={{ marginBottom: 10 }}*/}
      {/*  content={t('loginWithFacebook')}*/}
      {/*  onClick={() => handleSocialLogin('facebook')}*/}
      {/*/>*/}
      <Button
        icon='google'
        fluid
        color='google plus'
        style={{ marginBottom: 10 }}
        content={t('loginWithGoogle')}
        disabled={disabled}
        onClick={() => handleSocialLogin('google')}
      />
      {/* TODO: Add in Microsoft as a provider? */}
    </>
  );
}
