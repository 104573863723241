import {Item} from "semantic-ui-react";

export default function DisclosePersonal() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h2'>Disclosure of Your Personal Data</Item.Header>
      </Item>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h3'>Business Transactions</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We
          will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy
          Policy.
        </Item.Description>
      </Item>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h3'>Law enforcement</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so
          by law or in response to valid requests by public authorities (e.g. a court or a government agency).
        </Item.Description>
      </Item>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h3'>Other legal requirements</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          The Company may disclose Your Personal Data in the good faith belief that such action is necessary
          to:<br/><br/>
          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>Protect the personal safety of Users of the Service or the public</li>
            <li>Protect against legal liability</li>
          </ul>
        </Item.Description>
      </Item>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h3'>Security of Your Personal Data</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          The security of Your Personal Data is important to Us, but remember that no method of transmission over the
          Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means
          to protect Your Personal Data, We cannot guarantee its absolute security.
        </Item.Description>
      </Item>
    </>
  )
}