import {Item} from "semantic-ui-react";


export default function TermsAccounts() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h1'>User Accounts</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          In order to use most aspects of the Services, you must register for and maintain an active personal user
          Services account (“Account”). You must be at least 18 years of age, or the age of legal majority in your
          jurisdiction (if different than 18), to obtain an Account. Account registration requires you to submit to
          ExpatEvents certain personal information, such as your name, address, mobile phone number and age, as well as
          possibly at least one valid payment method (either a credit card or accepted payment partner). You agree to
          maintain accurate, complete, and up-to-date information in your Account. Your failure to maintain accurate,
          complete, and up-to-date Account information, including having an invalid or expired payment method on file,
          may result in your inability to access and use the Services or ExpatEvents’s termination of these Terms with
          you. You are responsible for all activity that occurs under your Account, and you agree to maintain the security
          and secrecy of your Account username and password at all times. Unless otherwise permitted by ExpatEvents in
          writing, you may only possess one Account.<br/><br/>
        </Item.Description>
      </Item></>
  )
}