import {Item} from "semantic-ui-react";

export default function TermsGoverning() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h1'>Governing Law</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the
          Service. Your use of the Application may also be subject to other local, state, national, or international
          laws.<br/><br/>
        </Item.Description>
      </Item>
    </>
  )
}