import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Form, Formik} from "formik";
import {setFilterCategory} from "../eventActions";
import {Button, Grid} from "semantic-ui-react";
import React from "react";
import MySelectInput from "../../../app/common/form/MySelectInput";
import {categoryData} from "../../../app/api/categoryOptions";

export function CategoryFilterForm() {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{category: ''}}
      onSubmit={(values, {setSubmitting}) => {
        dispatch(setFilterCategory(values.category))
        setSubmitting(false);
      }}
    >
      {({isSubmitting, dirty, isValid, resetForm}) => (
        <Form className="ui form">
          <Grid>
            <Grid.Column width={12} style={{paddingRight: 0}}>
              <MySelectInput
                name='category'
                placeholder={t('category')}
                options={categoryData}
              />
            </Grid.Column>
            <Grid.Column width={2} style={{paddingLeft: 0}}>
              <Button.Group>
                <Button type='submit' color='green' icon='check' loading={isSubmitting}
                        disabled={!isValid || !dirty || isSubmitting}/>

                <Button
                  color='red'
                  icon='x'
                  loading={isSubmitting}
                  disabled={!isValid || !dirty || isSubmitting}
                  onClick={() => {
                    resetForm();
                    dispatch(setFilterCategory(''))
                  }}
                />
              </Button.Group>
            </Grid.Column>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}