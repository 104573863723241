import {Item} from "semantic-ui-react";
import React from "react";
import PersonalData from "./PersonalData";
import UsageData from "./UsageData";
import ThirdParty from "./ThirdParty";
import AppCollection from "./AppCollection";
import TrackingTech from "./TrackingTech";
import UsePersonalData from "./UsePersonalData";
import RetainPersonal from "./RetainPersonal";
import TransferPersonal from "./TransferPersonal";
import DeletePersonal from "./DeletePersonal";
import DisclosePersonal from "./DisclosePersonal";

export default function CollectingUsing() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h1'>Collecting and Using Your Personal Data</Item.Header>
      </Item>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h2'>Types of Data Collected</Item.Header>
      </Item>
      <PersonalData />
      <UsageData />
      <ThirdParty />
      <AppCollection />
      <TrackingTech />
      <UsePersonalData />
      <RetainPersonal />
      <TransferPersonal />
      <DeletePersonal />
      <DisclosePersonal />
    </>
  )
}