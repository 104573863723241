import {Item} from "semantic-ui-react";

export default function TransferPersonal() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h2'>Transfer of Your Personal Data</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          Your information, including Personal Data, is processed at the Company's operating offices and in any other
          places where the parties involved in the processing are located. It means that this information may be
          transferred to — and maintained on — computers located outside of Your state, province, country or other
          governmental jurisdiction where the data protection laws may differ than those from Your
          jurisdiction.<br/><br/>

          Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement
          to that transfer.<br/><br/>

          The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
          accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization
          or a country unless there are adequate controls in place including the security of Your data and other
          personal information.
        </Item.Description>
      </Item>
    </>
  )
}