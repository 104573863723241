import {Item} from "semantic-ui-react";

export default function RetainPersonal() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h2'>Retention of Your Personal Data</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this
          Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal
          obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
          disputes, and enforce our legal agreements and policies.<br/><br/>

          The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a
          shorter period of time, except when this data is used to strengthen the security or to improve the
          functionality of Our Service, or We are legally obligated to retain this data for longer time
          periods.
        </Item.Description>
      </Item>
    </>
  )
}