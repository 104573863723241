import {Item} from "semantic-ui-react";

export default function AppCollection() {
	return (
		<>
			<Item>
				<Item.Header style={{marginBottom: 5}} as='h3'>Information Collected while Using the Application</Item.Header>
			</Item>
			<Item>
				<Item.Description>
					While using Our Application, in order to provide features of Our Application, We may collect, with
					Your prior permission:<br/><br/>
					<ul>
						<li>Information regarding your location</li>

						<li>Pictures and other information from your Device's camera and photo library</li>
					</ul>
					We use this information to provide features of Our Service, to improve and customize Our Service. The
					information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply
					stored on Your device.<br/><br/>

					You can enable or disable access to this information at any time, through Your Device settings.

				</Item.Description>
			</Item>
		</>
	)
}