import {useSelector} from "react-redux";
import {Outlet } from "react-router-dom";
import UnauthModal from "../../features/auth/UnauthModal";

export default function PrivateRoute({children}) {
		const {authenticated} = useSelector((state) => state.auth);

		if (!authenticated) {
			return (
				<UnauthModal routePath='/events'/>
			)
		}
	return children ? children : <Outlet />;
}