import {useDispatch} from "react-redux";
import {useState} from "react";
import {Button, Divider, Modal} from "semantic-ui-react";
import {openModal} from "../../app/common/modals/modalReducer";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function UnauthModal({routePath, setModalOpen, clicked=false}) {

  const [open, setOpen] = useState(true);

  const {t} = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleClose() {
    setOpen(false);
    if (clicked) {
      setModalOpen(false);
      return;
    }
    if (!routePath) {
      navigate('/events');
    }
    navigate(routePath);
  }

  function handleOpenLoginModal(modalType) {
    dispatch(openModal({modalType}));
    setOpen(false);
    if (clicked) {
      setModalOpen(false);
    }
  }

  return (<Modal open={open} size='mini' onClose={handleClose}>
      <Modal.Header content={t('unauthHeader')}/>
      <Modal.Content>
        <p>{t('unauthBody')}</p>
        <Button.Group widths={4}>
          <Button fluid color='teal' content={t('login')}
                  onClick={() => handleOpenLoginModal('LoginForm')}/>
          <Button.Or/>
          <Button fluid color='green' content={t('register')}
                  onClick={() => handleOpenLoginModal('RegisterForm')}/>
        </Button.Group>
        <Divider />
        <div style={{textAlign: 'center'}}>
          <p>{t('unauthCancelText')}</p>
          <Button onClick={handleClose} content={t('cancel')}/>
        </div>
      </Modal.Content>

    </Modal>)
}