import React from "react";
import { useDispatch } from "react-redux";
import { Menu, Button } from "semantic-ui-react";
import { openModal } from "../../app/common/modals/modalReducer";
import { useTranslation} from "react-i18next";

export default function SignedOutMenu() {

    const dispatch = useDispatch();

    const {t} = useTranslation();

    return(
        <Menu.Item position='right'>
          <Button basic inverted content={t('login')} onClick={() => dispatch(openModal({modalType: 'LoginForm' }))}/>
          <Button
            basic
            inverted
            content={t('register')}
            style={{ marginLeft: "0.5em" }}
            onClick={() => dispatch(openModal({modalType: 'RegisterForm' }))}
          />
        </Menu.Item>
    );
}