import {toast} from "react-toastify";
import {
    addDeleteRequest,
    setUserProfileData,
} from "./firestoreService";

import {
    getAuth,
    signInWithEmailAndPassword,
    signOut,
    createUserWithEmailAndPassword,
    updateProfile,
    FacebookAuthProvider,
    GoogleAuthProvider,
    signInWithPopup,
    updatePassword,
    sendPasswordResetEmail,
    deleteUser,
} from "firebase/auth";
import { getDatabase, ref as fbRef, push, query, orderByKey, limitToLast } from 'firebase/database';
import { getStorage, ref, uploadBytesResumable, deleteObject } from 'firebase/storage';
import { app } from '../config/firebase';


const auth = getAuth(app);
const db = getDatabase(app);

export function firebaseObjectToArray(snapshot) {
    if (snapshot) {
        return Object.entries(snapshot).map(e => Object.assign({}, e[1], {id: e[0]}));
    }
}

export function signInWithEmail(creds) {
    return  signInWithEmailAndPassword(auth, creds.email, creds.password);
}

export function signOutFirebaseUser() {
    return signOut(auth);
}

export function requestPasswordReset(creds) {
    return sendPasswordResetEmail(auth, creds.email);
}

export async function registerInFirebaseWithEmail(creds) {
    try {
        const result = await createUserWithEmailAndPassword(auth, creds.email, creds.password);
        await updateProfile(result.user, {displayName: creds.displayName});
        return await setUserProfileData(result.user);
    } catch (error) {
        throw error;
    }
}

export async function socialLogin(selectedProvider) {
    let provider;
    if (selectedProvider === 'facebook') {
        provider = new FacebookAuthProvider();
    }
    if (selectedProvider === 'google') {
        provider = new GoogleAuthProvider();
    }
    try {
        const result = await signInWithPopup(auth, provider);
        if (result._tokenResponse.isNewUser) {
            await setUserProfileData(result.user)
        }
    } catch (error) {
        toast.error(error.message)
    }
}

function getCurrentUser() {
    return auth.currentUser;
}

export function updateUserPassword(creds) {
    const user = getCurrentUser();
    return updatePassword(user, creds.newPassword1);
}

export function uploadToFirebaseStorage(file, filename) {
    const user = getCurrentUser();

    const storage = getStorage(app);
    const storageRef = ref(storage, `${user.uid}/user_images/${filename}`);
    return uploadBytesResumable(storageRef, file);
}

export function deleteFromFirebaseStorage(filename) {
    const userUid = getCurrentUser().uid;

    const storage = getStorage(app);

    const photoRef = ref(storage,`${userUid}/user_images/${filename}`);
    return deleteObject(photoRef);
}

export function addEventChatComment(eventId, values) {
    const user = getCurrentUser();
    const newComment = {
        displayName: user.displayName,
        photoURL: user.photoURL,
        uid: user.uid,
        text: values.comment,
        date: Date.now(),
        parentId: values.parentId
    }

    return push(fbRef(db, `chat/${eventId}` ), newComment);
}

export function getEventChatRef(eventId) {
    return query(fbRef(db, `chat/${eventId}`), orderByKey());
}

export function getUserFeedRef() {
    const user = getCurrentUser();

    if (!user) return;

    return query(fbRef(db, `posts/${user.uid}`), orderByKey(), limitToLast(5));
}

export async function deleteUserRequest(profile) {
    const user = getCurrentUser();

    // Here are the steps to deleting a user:
    // 1: Cancel any payment tokens with Stripe
    // Not Implemented yet

    // Deletion will need to be done manually as the userid is lost and the hooks throw errors.  So set a document
    // in deleted users with the user information so it can be deleted from a backend API.

    await addDeleteRequest(profile);

    // 5: Delete the user from the Auth service using Delete User.
    try {
        await deleteUser(user);
    } catch (error) {
        // Log the error
        // console.log(error);
    }




}