import {Flag} from "semantic-ui-react";

export default function NavFlag({lang}) {

	let data;

	switch (lang) {
		case 'es':
			data = 'es';
			break;
		case 'en':
		default:
			data = 'us';
	}

	return (
		<Flag name={data} />
	)
}