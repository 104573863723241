/* global google */
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";
import {Button, Grid} from "semantic-ui-react";
import MyPlaceInput from "../../../app/common/form/MyPlaceInput";
import React from "react";
import {setFilterVenue} from "../eventActions";

export default function VenueFilterForm() {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {cityFilter} = useSelector((state) => state.event);

  let options = {
    types: ["establishment"],
  }

  if (cityFilter) {
    options = {
      ...options,
      location: new google.maps.LatLng(cityFilter.city.latLng),
      radius: 1000,
    }
  }

  return (
    <Formik
      initialValues={{venue: {address: "", latLng: null}}}
      onSubmit={(values, {setSubmitting}) => {
        dispatch(setFilterVenue(values.venue.address));
        setSubmitting(false);
      }}
    >
      {({isSubmitting, dirty, isValid, resetForm}) => (
        <Form className="ui form">
          <Grid>
            <Grid.Column width={12} style={{paddingRight: 0}}>
              <MyPlaceInput
                name='venue'
                placeholder={t('venue')}
                options={options}
              />
            </Grid.Column>
            <Grid.Column width={2} style={{paddingLeft: 0}}>
              <Button.Group>
                <Button type='submit' color='green' icon='check' loading={isSubmitting}
                        disabled={!isValid || !dirty || isSubmitting}/>

                <Button
                  color='red'
                  icon='x'
                  loading={isSubmitting}
                  disabled={!isValid || !dirty || isSubmitting}
                  onClick={() => {
                    resetForm();
                    dispatch(setFilterVenue(''))
                  }}
                />
              </Button.Group>
            </Grid.Column>
          </Grid>
        </Form>
      )}

    </Formik>
  )
}