import {Item} from "semantic-ui-react";
import React from "react";

export function Definitions() {
	return (
		<>
			<Item>
				<Item.Header style={{marginBottom: 5}} as='h2'>Definition</Item.Header>
			</Item>
		<Item>
			<Item.Content>

				<Item.Meta>For the purposes of this Privacy Policy:</Item.Meta>
				<Item.Description>
					<ul>
						<li><strong>Account</strong> means a unique account created for You to access our Service
							or parts of our Service.</li>
						<br/>
						<li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common
							control with a party, where "control" means ownership of 50% or more of the shares, equity
							interest or other securities entitled to vote for election of directors or other
							managing authority.</li>
						<br/>
						<li><strong>Application</strong> refers to ExpatEvents, the software program provided by the Company.</li>
						<br/>
						<li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
							refers to ExpatEvents by The BaldTraveler.</li>
						<br/>
						<li><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other
							device by a website, containing the details of Your browsing history on that website
							among its many uses.</li>
						<br/>
						<li><strong>Country</strong> refers to: Florida, United States</li>
						<br/>
						<li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone
							or a digital tablet.</li>
						<br/>
						<li><strong>Personal Data</strong> is any information that relates to an identified or identifiable
							individual.</li>
						<br/>
						<li><strong>Service</strong> refers to the Application or the Website or both.</li>
						<br/>
						<li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf
							of the Company. It refers to third-party companies or individuals employed by the Company to facilitate
							the Service, to provide the Service on behalf of the Company, to perform services related to the Service
							or to assist the Company in analyzing how the Service is used.</li>
						<br/>
						<li><strong>Third-party Social Media Service</strong> refers to any website or any social network website
							through which a User can log in or create an account to use the Service.</li>
						<br/>
						<li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the
							Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
						<br/>
						<li><strong>Website</strong> refers to ExpatEvents by The BaldTraveler, accessible from
							https://www.ExpatEvents.net</li>
						<br/>
						<li><strong>You</strong> means the individual accessing or using the Service, or the company, or other
							legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
					</ul>
				</Item.Description>
			</Item.Content>
		</Item>
		</>
	)
}