import React from 'react';
import {Grid} from 'semantic-ui-react';
import EventDetailedHeader from './EventDetailedHeader';
import EventDetailedInfo from './EventDetailedInfo';
import EventDetailedChat from './EventDetailedChat';
import EventDetailedSibebar from './EventDetailedSidebar';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import useFireStoreDoc from '../../../app/hooks/useFirestoreDoc';
import {listenToEventFromFirestore} from '../../../app/firestore/firestoreService';
import {listenToSelectedEvent} from '../eventActions';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import {useTranslation} from "react-i18next";

export default function EventDetailedPage() {
  const {id} = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {currentUser} = useSelector((state) => state.auth);
  const event = useSelector((state) =>
    state.event.selectedEvent);
  const {loading, error} = useSelector((state) => state.async);
  const {t} = useTranslation();
  const isHost = event?.hostUid === currentUser?.uid;
  const isGoing = event?.attendees?.some(a => a.id === currentUser?.uid);

  useFireStoreDoc({
    query: () => listenToEventFromFirestore(id),
    data: (event) => dispatch(listenToSelectedEvent(event)),
    deps: [id, dispatch],
  });

  if (loading || (!event && !error))
    return <LoadingComponent content={t('loadingEvent')}/>;

  if (error) return navigate('/error');

  return (
    <Grid>
      <Grid.Column width={10}>
        <EventDetailedHeader event={event} isGoing={isGoing} isHost={isHost}/>
        <EventDetailedInfo event={event}/>
        <EventDetailedChat eventId={event.id}/>
      </Grid.Column>
      <Grid.Column width={6}>
        <EventDetailedSibebar attendees={event?.attendees} hostUid={event?.hostUid}/>
      </Grid.Column>
    </Grid>
  );
}
