/* global google */
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {Button, Confirm, Header, Segment} from "semantic-ui-react";
import {Formik, Form} from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import MySelectInput from "../../../app/common/form/MySelectInput";
import {categoryData} from "../../../app/api/categoryOptions";
import MyDateInput from "../../../app/common/form/MyDateInput";
import MyPlaceInput from "../../../app/common/form/MyPlaceInput";
import useFireStoreDoc from "../../../app/hooks/useFirestoreDoc";
import {
  addEventToFirestore,
  cancelEventToggle,
  listenToEventFromFirestore,
  updateEventInFirestore,
} from "../../../app/firestore/firestoreService";
import {clearSelectedEvent, listenToSelectedEvent} from "../eventActions";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

export default function EventForm() {
  const dispatch = useDispatch();

  const [loadingCancel, setLoadingCancel] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const {id} = useParams();

  let navigate = useNavigate();

  const location = useLocation();

  const {selectedEvent} = useSelector((state) => state.event);

  const {loading, error} = useSelector((state) => state.async);

  const {t} = useTranslation();

  useEffect(() => {
    if (location.pathname !== '/events/new') return
    dispatch(clearSelectedEvent())
  }, [location.pathname, dispatch]);
  

  const initialValues = selectedEvent ?? {
    title: "",
    category: "",
    description: "",
    city: {address: "", latLng: null},
    venue: {address: "", latLng: null},
    date: "",
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(t('titleRequired')),
    category: Yup.string().required(t('categoryRequired')),
    description: Yup.string().required(),
    city: Yup.object().shape({
      address: Yup.string().required(t('cityRequired')),
    }),
    venue: Yup.object().shape({
      address: Yup.string().required(t('venueRequired')),
    }),
    date: Yup.string().required(),
  });

  async function handleCancelToggle(event) {
    setConfirmOpen(false);
    setLoadingCancel(true);
    try {
      await cancelEventToggle(event);
      setLoadingCancel(false);
    } catch (error) {
      setLoadingCancel(false);
      toast.error(error.message);
    }
  }

  useFireStoreDoc({
    query: () => listenToEventFromFirestore(id),
    data: (event) => dispatch(listenToSelectedEvent(event)),
    deps: [id, dispatch],
    shouldExecute: id !== selectedEvent?.id && location.pathname !== '/events/new',
  });

  if (loading) return <LoadingComponent content={t('loadingEvent')}/>;

  if (error) return navigate("/error");

  return (
    <Segment clearing>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, {setSubmitting}) => {
          try {
            selectedEvent
              ? await updateEventInFirestore(values)
              : await addEventToFirestore(values);
            setSubmitting(false);
            navigate("/events");
          } catch (error) {
            toast.error(error.message);
            setSubmitting(false);
          }
        }}
      >
        {({isSubmitting, dirty, isValid, values}) => (
          <Form className='ui form'>
            <Header sub color='teal' content={t('eventDetails')}/>
            <MyTextInput name='title' placeholder={t('eventTitle')}/>
            <MySelectInput
              name='category'
              placeholder={t('category')}
              options={categoryData}
            />
            <MyTextArea name='description' placeholder={t('description')} rows={3}/>
            <Header sub color='teal' content={t('eventLocationDetails')}/>
            <MyPlaceInput name='city' placeholder={t('city')}/>
            <MyPlaceInput
              name='venue'
              placeholder={t('venue')}
              options={{
                location: new google.maps.LatLng(values.city.latLng),
                radius: 1000,
                types: ["establishment"],
              }}
              disabled={!values.city.latLng}
            />
            <MyDateInput
              name='date'
              placeholderText={t('eventDate')}
              timeFormat='HH:mm'
              showTimeSelect
              timeCaption={t('time')}
              dateFormat='MMMM d, yyyy h:mm a'
              autoComplete='off'
            />
            {selectedEvent && (
              <Button
                type='button'
                loading={loadingCancel}
                floated='left'
                content={
                  selectedEvent.isCancelled
                    ? t('reactivateEvent')
                    : t('cancelEvent')
                }
                color={selectedEvent.isCancelled ? "green" : "red"}
                onClick={() => setConfirmOpen(true)}
              />
            )}
            <Button
              type='submit'
              floated='right'
              positive
              content={t('submit')}
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
            />
            <Button
              type='submit'
              floated='right'
              content={t('cancel')}
              as={Link}
              to='/events'
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
      <Confirm
        content={
          selectedEvent?.isCancelled
            ? t('reactivateEventQuestion')
            : t('cancelEventQuestion')
        }
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => handleCancelToggle(selectedEvent)}
      />
    </Segment>
  );
}
