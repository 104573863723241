import React from "react";
import { Segment, Item, Icon, List, Button, Label } from "semantic-ui-react";
import EventListAttendee from "./EventListAttendee";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { useTranslation} from "react-i18next";

export default function EventListItem({ event }) {

  const {t} = useTranslation();

  return (
    <Segment.Group style={{marginBottom: 10}}>
      <Segment>
        <Item.Group>
          <Item>
            <Item.Image size='tiny' circular src={event.hostPhotoURL || '/assets/user.png'} />
            <Item.Content>
              <Item.Header content={event.title} />
              <Item.Description>{t('postedBy')}: <Link to={`/profile/${event.hostUid}`} >{event.hostedBy}</Link> </Item.Description>
              {event.isCancelled && (
                <Label
                  style={{ top: "-40px" }}
                  ribbon='right'
                  color='red'
                  content='This event has been cancelled'
                />
              )}
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <Segment>
        <span>
          <Icon name='clock' /> {format(event.date, "MMMM d, yyyy h:mm a")}
          <Icon name='marker' /> {event.venue.address}
        </span>
      </Segment>
      <Segment secondary>
        <List horizontal>
          {event.attendees.map((attendee) => (
            <EventListAttendee attendee={attendee} key={attendee.id} />
          ))}
        </List>
      </Segment>
      <Segment clearing>
        <div>{event.description}</div>
        {/* TODO: Add this back when RBAC is added and ADMIN role is defined. */}
        {/*<Button*/}
        {/*  color='red'*/}
        {/*  floated='right'*/}
        {/*  content='Delete'*/}
        {/*  onClick={() => deleteEventInFirestore(event.id)}*/}
        {/*/>*/}
        <Button
          color='teal'
          floated='right'
          content={t('view')}
          as={Link}
          to={`/events/${event.id}`}
        />
      </Segment>
    </Segment.Group>
  );
}
