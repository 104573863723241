import {Form, Formik} from 'formik';
import React from 'react';
import {Button, Header, Label, Segment} from 'semantic-ui-react';
import * as Yup from 'yup';
import MyTextInput from '../../app/common/form/MyTextInput';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {updateUserPassword} from '../../app/firestore/firebaseService';
import {useTranslation} from "react-i18next";
import {openModal} from "../../app/common/modals/modalReducer";

export default function AccountPage() {
  const {currentUser} = useSelector((state) => state.auth);
  const {currentUserProfile} = useSelector((state) => state.profile);

  const {t}= useTranslation();

  const dispatch = useDispatch();

  return (
    <Segment.Group>
      <Segment>
        <Header dividing size='large' content={t('account')}/>
        {currentUser.providerId === 'password' && (
          <>
            <Header color='teal' sub content={t('changePassword')}/>
            <p>{t('cPassBody')}</p>
            <Formik
              initialValues={{newPassword1: '', newPassword2: ''}}
              validationSchema={Yup.object({
                newPassword1: Yup.string()
                  .required(t('passwordRequired'))
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                    t('passwordRules')
                  ),
                newPassword2: Yup.string().oneOf(
                  [Yup.ref('newPassword1'), null],
                  t('passwordNoMatch')
                ),
              })}
              onSubmit={async (values, setSubmitting, setErrors) => {
                try {
                  await updateUserPassword(values);
                } catch (error) {
                  setErrors({auth: error.message});
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              {({errors, isSubmitting, isValid, dirty}) => (
                <Form className='ui form'>
                  <MyTextInput
                    name='newPassword1'
                    type='password'
                    placeholder={t('newPassword')}
                  />
                  <MyTextInput
                    name='newPassword2'
                    type='password'
                    placeholder={t('confirmPassword')}
                  />
                  {errors.auth && (
                    <Label
                      basic
                      color='red'
                      style={{marginBottom: 10}}
                      content={errors.auth}
                    />
                  )}
                  <Button
                    style={{display: 'block'}}
                    type='submit'
                    disabled={!isValid || isSubmitting || !dirty}
                    size='large'
                    loading={isSubmitting}
                    positive
                    content={t('updatePassword')}
                  />
                </Form>
              )}
            </Formik>
          </>
        )}
        {currentUser.providerId === 'facebook.com' && (
          <>
            <Header color='teal' sub content={t('facebookAccount')}/>
            <p>{t('facebookBody')}</p>
            <Button
              icon='facebook'
              color='facebook'
              as={Link}
              to='https://www.facebook.com'
              content={t('facebookBtn')}
            />
          </>
        )}
        {currentUser.providerId === 'google.com' && (
          <>
            <Header color='teal' sub content={t('googleAccount')}/>
            <p>{t('googleBody')}</p>
            <Button
              icon='google'
              color='google plus'
              as={Link}
              to='https://accounts.google.com'
              content={t('googleBtn')}
            />
          </>
        )}
      </Segment>
        <Segment tertiary>
            <Header color='red' content={t('deleteAccountText')}/>
            <Button
                icon='trash alternate outline'
                color='red'
                content={t('delete')}
                onClick={() => dispatch(openModal({modalType: 'DeleteAccountForm' }))}
            />
        </Segment>
      <Segment attached>
        <Header dividing size='large' content={t('acceptances')} textAlign='center'/>
        <ul>
          <li>
            {t('you')} <strong>{currentUserProfile?.privacy ? t('have') : t('haveNot')} </strong> {t('accepted')} <Link to='/privacy'>{t('privacy')}</Link>.
          </li>
          <li>
            {t('you')} <strong>{currentUserProfile?.terms ? t('have') : t('haveNot')} </strong> {t('accepted')} <Link to='/terms'>{t('terms')}</Link>.
          </li>
        </ul>
      </Segment>
      <Segment attached>
        <Header dividing size='large' content={t('accountStatus')} textAlign='center'/>
        {currentUserProfile?.paid ? (
          <Label color='green' content={t('paidAccount')}/>
        ) : (<Label color='blue' content={t('freeAccount')}/>)}
        {
          currentUserProfile?.admin &&
          <Label color='red' content={t('admin')}/>
        }
        {
          currentUserProfile?.groupOwner &&
          <Label color='teal' content={t('groupOwner')}/>
        }
        {
          currentUserProfile?.groupMemberCount > 0 &&
          <Label color='orange'>{t('memberOf')} {currentUserProfile?.groupMemberCount} {currentUserProfile?.groupMemberCount === 1 ? t('groupS') : t('groupP')}</Label>
        }
      </Segment>
    </Segment.Group>
  );
}
