import {Item} from "semantic-ui-react";
import React from "react";

export default function TermsHeader() {
	return (
		<>
			<Item>
				<Item.Header style={{marginBottom: 5}} as='h1'>Terms and Conditions</Item.Header>
			</Item>
			<Item>
				<Item.Description>Please read these terms and conditions carefully before using Our Service.<br/><br/></Item.Description>

			</Item>
		</>
	)
}