import React, { useState } from 'react';
import { Button, Grid, Header, Tab } from 'semantic-ui-react';
import { format } from 'date-fns';
import ProfileForm from './ProfileForm';
import {useTranslation} from "react-i18next";

export default function AboutTab({ profile, isCurrentUser }) {
  const [editMode, setEditMode] = useState(false);

  const {t} = useTranslation();

  console.log(profile.createdAt);
  return (
    <Tab.Pane>
      <Grid>
        <Grid.Column width={16}>
          <Header
            floated='left'
            icon='user'
            content={`${t('about')} ${profile.displayName}`}
          />
          {isCurrentUser && 
          <Button
            onClick={() => setEditMode(!editMode)}
            floated='right'
            basic
            content={editMode ? t('cancel') : t('edit')}
          />
}
        </Grid.Column>
        <Grid.Column width={16}>
          {editMode ? (
            <ProfileForm profile={profile}/>
          ) : (
            <>
              <div style={{ marginBottom: 10 }}>
                <strong>
                  {t('memberSince')}: {format(profile.createdAt, 'dd MMM yyy')}
                </strong>
                <div>{profile.description || null}</div>
              </div>
            </>
          )}
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  );
}
