import {Item} from "semantic-ui-react";

export default function DeletePersonal() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h2'>Delete Your Personal Data</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          You have the right to delete or request that We assist in deleting the Personal Data that We have collected
          about You.<br/><br/>

          Our Service may give You the ability to delete certain information about You from within the
          Service.<br/><br/>

          You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one,
          and visiting the account settings section that allows you to manage Your personal information. You may also
          contact Us to request access to, correct, or delete any personal information that You have provided to
          Us.<br/><br/>

          Please note, however, that We may need to retain certain information when we have a legal obligation or lawful
          basis to do so.
        </Item.Description>
      </Item>
    </>
  )
}