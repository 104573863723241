import {Item} from "semantic-ui-react";

export default function TermsUpload() {
	return (
		<>
			<Item>
				<Item.Header style={{marginBottom: 5}} as='h1'>Uploaded Data (Images)</Item.Header>
			</Item>
			<Item>
				<Item.Description>
					As part of your user account with ExpatEvents we allow you to upload images for use as a profile image.  This
					image is displayed on various pages within the site.  You can manage your images by going to the photo's tab
					on you profile page.<br/><br/>

					<strong>By uploading an image you agree to the following terms:</strong><br/><br/>

					<ul>
						<li>You own the copyright to the image and/or are authorized to use the image.</li>
						<li>You grant ExpatEvents a non-exclusive copyright to use and display the image on the site and app.</li>
						<li>The image is not obscene or offensive.</li>
						<li>The the Company can remove an image if it receives a DMCA notification about the image.</li>
						<li>You do not hold the Company liable or responsible should someone copy the image from the site.</li>
					</ul><br/><br/>

				</Item.Description>
			</Item>
		</>
	)
}