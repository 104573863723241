import {Item} from "semantic-ui-react";

export function TermsTranslation() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h1'>Translation Interpretation</Item.Header>
      </Item>
      <Item>
        <Item.Description>'These Terms and Conditions may have been translated if We have made them available to You on
          our Service. You agree that the original English text shall prevail in the case of a dispute.<br/><br/>
        </Item.Description>
      </Item>
    </>
  )
}