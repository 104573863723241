import {Item} from "semantic-ui-react";

export default function TermsLiability() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h1'>Limitation of Liability</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers
          under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the
          amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the
          Service.<br/><br/>

          To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable
          for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to,
          damages for loss of profits, loss of data or other information, for business interruption, for personal
          injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service,
          third-party software and/or third-party hardware used with the Service, or otherwise in connection with any
          provision of this Terms), even if the Company or any supplier has been advised of the possibility of such
          damages and even if the remedy fails of its essential purpose.<br/><br/>

          Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or
          consequential damages, which means that some of the above limitations may not apply. In these states, each
          party's liability will be limited to the greatest extent permitted by law.<br/><br/>
        </Item.Description>
      </Item>
    </>
  )
}