import {Item} from "semantic-ui-react";
import React from "react";

export default function Interpretation() {
	return (
		<>
			<Item>
				<Item.Header style={{marginBottom: 5}} as='h2'>Interpretation</Item.Header>
			</Item>
		<Item>
			<Item.Content>

				<Item.Description>The words of which the initial letter is capitalized have meanings defined under the
					following conditions. The following definitions shall have the same meaning regardless of whether
					they appear in singular or in plural.</Item.Description>
			</Item.Content>
		</Item>
		</>
	)
}