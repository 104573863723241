import {Item} from "semantic-ui-react";

export function TermsChanges() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h1'>Changes to These Terms and Conditions</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is
          material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking
          effect. What constitutes a material change will be determined at Our sole discretion.<br/><br/>

          By continuing to access or use Our Service after those revisions become effective, You agree to be bound by
          the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website
          and the Service.<br/><br/>
        </Item.Description>
      </Item>
    </>
  )
}