import {Loader} from "semantic-ui-react";
import React from "react";
import {Formik, Form, Field} from "formik";
import {addEventChatComment} from "../../../app/firestore/firebaseService";
import {toast} from "react-toastify";
import * as Yup from 'yup';
import {useTranslation} from "react-i18next";



export default function EventDetailedChatForm({eventId, parentId, closeForm}) {

  const {t} = useTranslation();

    return (
        <Formik initialValues={{comment: ''}} onSubmit={async (values, {setSubmitting, resetForm}) => {
            try {
                await addEventChatComment(eventId, {...values, parentId});
                resetForm();
            } catch (error) {
                toast.error(error.message);
            } finally {
                setSubmitting(false)
                closeForm({open: false, commentId: null});
            }
        }}
        validationSchema={Yup.object({
            comment: Yup.string().required()
        })}
        >
            {({isSubmitting, handleSubmit, isValid}) => (
                <Form className='ui form reply'>
                    <Field name='comment'>
                        {({field}) => (
                            <div style={{position: 'relative'}}>
                                <Loader active={isSubmitting}/>
                                <textarea rows={2} {...field}
                                          placeholder={t('chatInstructions')}
                                          onKeyDown={(e) => {
                                              if (e.key === 'Enter' && e.shiftKey) {
                                                  return;
                                              }
                                              if (e.key === 'Enter' && !e.shiftKey) {
                                                  e.preventDefault()
                                                  isValid && handleSubmit();
                                              }
                                          }}/>
                            </div>
                        )}
                    </Field>


                </Form>
            )}

        </Formik>
    )
}