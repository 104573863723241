import {initializeApp} from 'firebase/app';
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "expatevents-firebase.firebaseapp.com",
  projectId: "expatevents-firebase",
  storageBucket: "expatevents-firebase.appspot.com",
  messagingSenderId: "242054802198",
  appId: "1:242054802198:web:fb5e43ba8975254b183e37",
  measurementId: "G-B3WJWRMHTV",
};


export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
