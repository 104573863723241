import {Item} from "semantic-ui-react";

export default function TrackingTech() {
	return (
		<>
			<Item>
				<Item.Header style={{marginBottom: 5}} as='h3'>Tracking Technologies and Cookies</Item.Header>
			</Item>
			<Item>
				<Item.Description>
					We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
					information. Tracking technologies used are beacons, tags, and scripts to collect and track information and
					to improve and analyze Our Service. The technologies We use may include:<br/><br/>
					<ul>
						<li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can
							instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You
							do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted
							Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
						<li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small
							electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs)
							that permit the Company, for example, to count users who have visited those pages or opened an email and
							for other related website statistics (for example, recording the popularity of a certain section and
							verifying system and server integrity).</li>
					</ul>
					Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or
					mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.
					<br/><br/>
					Learn more about cookies on the Free Privacy Policy website article.<br/><br/>

					We use both Session and Persistent Cookies for the purposes set out below:<br/><br/>
					<ul>
						<li><strong>Necessary / Essential Cookies</strong><br/><br/>

					Type: Session Cookies<br/><br/>

					Administered by: Us<br/><br/>

					Purpose: These Cookies are essential to provide You with services available through the Website and to enable
					You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts.
					Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies
					to provide You with those services.<br/><br/></li>
						<li><strong>Cookies Policy / Notice Acceptance Cookies</strong><br/><br/>

					Type: Persistent Cookies<br/><br/>

					Administered by: Us<br/><br/>

					Purpose: These Cookies identify if users have accepted the use of cookies on the Website.<br/><br/>
					</li>
						<li><strong>Functionality Cookies</strong><br/><br/>

					Type: Persistent Cookies<br/><br/>

					Administered by: Us<br/><br/>

					Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering
							your login details or language preference. The purpose of these Cookies is to provide You with a more
							personal experience and to avoid You having to re-enter your preferences every time You use the
							Website.<br/><br/>
						</li>
					</ul>
					For more information about the cookies we use and your choices regarding cookies, please visit our Cookies
					Policy or the Cookies section of our Privacy Policy.
				</Item.Description>
			</Item>
		</>
	)
}