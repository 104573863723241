import React from 'react';
import { Link } from 'react-router-dom';
import { Item, Label, Segment } from 'semantic-ui-react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

export default function EventDetailedSibebar({ attendees, hostUid }) {

  const {t} = useTranslation();
  const {currentUser} = useSelector((state) => state.auth);

  return (
    <>
      <Segment
        textAlign='center'
        style={{ border: 'none' }}
        attached='top'
        secondary
        inverted
        color='teal'
      >
        {attendees.length} {attendees.length > 1 ? t('people') : t('person')} {t('going')}
      </Segment>
      <Segment attached>
        <Item.Group relaxed divided>
          {attendees.map((attendee) => (
            <Item as={Link} to={`/profile/${attendee.id}`} key={attendee.id} style={{ position: 'relative' }}>
              {hostUid === attendee.id && (
                <Label
                  style={{ position: 'absolute' }}
                  color='orange'
                  ribbon='right'
                  content={t('host')}
                />
              )}
              {currentUser?.uid === attendee.id && (
                <Label
                  style={{ position: 'absolute', marginTop: '4em' }}
                  color='teal'
                  ribbon='right'
                  content={t('you')}
                />
              )}
              <Item.Image
                size='tiny'
                src={attendee.photoURL || '/assets/user.png'}
              />
              <Item.Content verticalAlign='middle'>
                <Item.Header as='h3'>
                  <span>{attendee.displayName}</span>
                </Item.Header>
              </Item.Content>
            </Item>
          ))}
        </Item.Group>
      </Segment>
    </>
  );
}
