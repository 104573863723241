import React, { useState } from 'react';
import { Button, Grid, Header } from 'semantic-ui-react';
import PhotosWidgetDropZone from './PhotosWidgetDropzone';
import PhotoWidgetCropper from './PhotoWidgetCropper';
import cuid from 'cuid';
import { getFileExtension } from '../util/util';
import { uploadToFirebaseStorage } from '../../firestore/firebaseService';
import { toast } from 'react-toastify';
import { updateUserProfilePhoto } from '../../firestore/firestoreService';
import {useTranslation} from "react-i18next";

export default function PhotoUploadWidget({ setEditMode }) {
  const [files, setFiles] = useState([]);
  const [cropper, setCropper] = useState(null);
  const [loading, setLoading] = useState(false);

  const {t} = useTranslation();

  function handleUploadImage() {
    setLoading(true);
    const filename = cuid() + '.' + getFileExtension(files[0].name);
    cropper.getCroppedCanvas().toBlob(image => {
      const uploadTask = uploadToFirebaseStorage(image, filename);
      uploadTask.on(
        'state_change',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          toast.error(error.message);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            updateUserProfilePhoto(downloadURL, filename)
              .then(() => {
                setLoading(false);
                handleCancelCrop();
                setEditMode(false);
              })
              .catch((error) => {
                toast.error(error.message);
                setLoading(false);
              });
          });
        }
      );
    })

  }

  function handleCancelCrop() {
    setFiles([]);
    setCropper(null);
  }

  return (
    <Grid>
      <Grid.Column width={4}>
        <Header color='teal' sub content={t('step1photo')} />
        <PhotosWidgetDropZone setFiles={setFiles} />
      </Grid.Column>
      <Grid.Column width={1} />
      <Grid.Column width={4}>
        <Header color='teal' sub content={t('step2photo')} />
        {files.length > 0 && (
          <PhotoWidgetCropper
            setCropper={setCropper}
            imagePreview={files[0].preview}
          />
        )}
      </Grid.Column>
      <Grid.Column width={1} />
      <Grid.Column width={4}>
        <Header color='teal' sub content={t('step3photo')} />
        {files.length > 0 && (
          <>
            <div
              className='img-preview'
              style={{ minHeight: 200, minWidth: 200, overflow: 'hidden' }}
            />
            <Button.Group>
              <Button style={{ width: 100 }} positive icon='check' onClick={handleUploadImage} loading={loading}/>
              <Button style={{ width: 100 }} icon='close' onClick={handleCancelCrop} disabled={loading}/>
            </Button.Group>
          </>
        )}
      </Grid.Column>
    </Grid>
  );
}
