import {Item} from "semantic-ui-react";

export default function TermsSeverability() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h1'>Severability and Waiver</Item.Header>
      </Item>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h2'>Severability</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and
          interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable
          law and the remaining provisions will continue in full force and effect.<br/><br/>
        </Item.Description>
      </Item>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h2'>Waiver</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          Except as provided herein, the failure to exercise a right or to require performance of an obligation under
          these Terms shall not effect a party's ability to exercise such right or require such performance at any time
          thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.<br/><br/>
        </Item.Description>
      </Item>
    </>
  )
}