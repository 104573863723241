import {Item} from "semantic-ui-react";

export default function ChildsPrivacy() {
  return (
    <>
      <Item>
        <Item.Header style={{marginBottom: 5}} as='h1'>Children's Privacy</Item.Header>
      </Item>
      <Item>
        <Item.Description>
          Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable
          information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child
          has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data
          from anyone under the age of 13 without verification of parental consent, We take steps to remove that
          information from Our servers.<br/><br/>

          If We need to rely on consent as a legal basis for processing Your information and Your country requires
          consent from a parent, We may require Your parent's consent before We collect and use that information.
        </Item.Description>
      </Item>
    </>
  )
}