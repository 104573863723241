import React, {useEffect} from "react";
import {NavLink} from "react-router-dom";
import {Button, Container, Dropdown, Menu} from "semantic-ui-react";
import SignedOutMenu from "./SignedOutMenu";
import SignedInMenu from "./SignedInMenu";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import NavFlag from "./NavFlag";
import LanguageItem from "./LanguageItem";

export default function NavBar() {
  const {authenticated} = useSelector((state) => state.auth);

  // Add nav namespace in the future??
  const {t, i18n} = useTranslation();

  useEffect(() => {
    if (localStorage.getItem('i18nextLng')?.length > 2) {
      i18next.changeLanguage('en');
    }
  }, [])

  function handleLanguageChange(lang) {
    i18n.changeLanguage(lang);
  }

  return (
    <Menu inverted fixed='top'>
      <Container>
        <Menu.Item as={NavLink} to='/' header>
          <img src='/assets/logo.png' alt='logo' style={{marginRight: 15}}/>
          ExpatEvents
        </Menu.Item>
        <Menu.Item as={NavLink} to='/events' name={t('events')}/>

        {authenticated && (
          <Menu.Item as={NavLink} to='/events/new'>
            <Button positive inverted content={t('create')}/>
          </Menu.Item>
        )}
        <Menu.Item position='right'>
          <NavFlag lang={i18next.language} />
          <Dropdown pointing='top left'>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleLanguageChange('en')}><LanguageItem lang='en'/></Dropdown.Item>
              <Dropdown.Item onClick={() => handleLanguageChange('es')}><LanguageItem lang='es'/></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
        {authenticated ? <SignedInMenu/> : <SignedOutMenu/>}
      </Container>
    </Menu>
  );
}
