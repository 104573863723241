import React from "react";
import ModalWapper from "../../app/common/modals/ModalWrapper";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import {Button, Divider, Label} from "semantic-ui-react";
import {useDispatch} from "react-redux";
import {closeModal} from "../../app/common/modals/modalReducer";
import {registerInFirebaseWithEmail} from "../../app/firestore/firebaseService";
import SocialLogin from "./SocialLogin";
import MyCheckBox from "../../app/common/form/MyCheckBox";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function RegisterForm() {
  const dispatch = useDispatch();

  const {t} = useTranslation();

  return (
    <ModalWapper size='mini' header={t('registerHeader')}>
      <Formik
        initialValues={{displayName: "", email: "", password: "", privacy: false, terms: false}}
        validationSchema={Yup.object({
          displayName: Yup.string().required(t('displayValid')),
          email: Yup.string().required(t('emailValid')).email(),
          password: Yup.string()
            .required(t('passwordNull'))
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
              t('passwordRules')
            ),
          privacy: Yup.bool().isTrue(t('agreePrivacy')),
          terms: Yup.bool().isTrue(t('agreeTerms')),
        })}
        onSubmit={async (values, {setSubmitting, setErrors}) => {
          try {
            await registerInFirebaseWithEmail(values);
            setSubmitting(false);
            dispatch(closeModal());
          } catch (error) {
            setErrors({auth: error.message});
            setSubmitting(false);
          }
        }}
      >
        {({isSubmitting, isValid, dirty, errors, values}) => (
          <Form className='ui form'>
            <MyTextInput
              name='displayName'
              placeholder={t('displayNameHandle')}
            />
            <MyTextInput name='email' placeholder={t('email')}/>
            <MyTextInput
              name='password'
              placeholder={t('password')}
              type='password'
            />
            <Link to='/privacy'>{t('privacy')}</Link><br/>
            <Link to='/terms'>{t('terms')}</Link><br/>
            <MyCheckBox name='privacy' label={t('privacyAgreeText')}/>
            <MyCheckBox name='terms' label={t('termsAgreeText')}/>
            {errors.auth && (
              <Label
                basic
                color='red'
                style={{marginBottom: 10}}
                content={errors.auth}
              />
            )}
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type='submit'
              fluid
              size='large'
              color='teal'
              content={t('register')}
            />
            <Divider horizontal>Or</Divider>
            <SocialLogin disabled={!(values.privacy && values.terms)}/>
          </Form>
        )}
      </Formik>
    </ModalWapper>
  );
}
