import {Item} from "semantic-ui-react";
import React from "react";
import Interpretation from "./Interpretation";
import {Definitions} from "./Definitions";

export default function InterpretationDefinitions() {
	return (
		<>
			<Item>

					<Item.Header style={{marginBottom: 5}} as='h1'>Interpretation and Definitions</Item.Header>

			</Item>
			<Interpretation />
			<Definitions />
		</>
	)
}