import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import MyPlaceInput from "../../../app/common/form/MyPlaceInput";
import React from "react";
import {useDispatch} from "react-redux";
import {setFilterCity} from "../eventActions";
import {Button, Grid} from "semantic-ui-react";

export default function CityFilterForm() {

  const {t} = useTranslation();
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{city: {address: "", latLng: null}}}
      onSubmit={(values, {setSubmitting}) => {
        dispatch(setFilterCity(values));
        setSubmitting(false);
      }}
    >
      {({isSubmitting, dirty, isValid, resetForm}) => (
        <Form className="ui form">
          <Grid>
            <Grid.Column width={12} style={{paddingRight: 0}}>
              <MyPlaceInput name='city' placeholder={t('city')}/>
            </Grid.Column>
            <Grid.Column width={2} style={{paddingLeft: 0}}>
              <Button.Group>
                <Button type='submit' color='green' icon='check' loading={isSubmitting}
                        disabled={!isValid || !dirty || isSubmitting}/>

                <Button
                  color='red'
                  icon='x'
                  loading={isSubmitting}
                  disabled={!isValid || !dirty || isSubmitting}
                  onClick={() => {
                    resetForm();
                    dispatch(setFilterCity(''))
                  }}
                />
              </Button.Group>
            </Grid.Column>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}